
























































import Vue, { PropType } from "vue"
import { BTooltip } from "bootstrap-vue"
import type { WithEvents } from "vue-typed-emit"

import type { AudioEntry } from "@/modules/audio/types"

import { RtbButton, RtbSelect, RtbCheckbox } from "@/components/ui"

export const Event = {
  AUDIO_STOP: "AUDIO_STOP",
  AUDIO_PLAY: "AUDIO_PLAY",
  AUDIO_ENDED: "AUDIO_ENDED"
}

export interface Events {
  input: string
  play: undefined
  stop: undefined
  ["update:loop"]: boolean
  ["focus:select"]: undefined
  ["blur:select"]: undefined
}

export default (Vue as WithEvents<Events>).extend({
  name: "AudioPlayer",
  components: {
    BTooltip,
    RtbButton,
    RtbSelect,
    RtbCheckbox
  },
  props: {
    value: { type: String },
    items: {
      type: Array as PropType<AudioEntry[]>,
      default() {
        return []
      }
    },
    active: {
      type: Boolean
    },
    loop: {
      default: false,
      type: Boolean
    },
    identity: {
      type: String,
      default: "id"
    }
  },
  computed: {
    valueModel: {
      get(): string | undefined {
        return this.value
      },
      set(value: string) {
        this.$emit("input", value)
      }
    },
    loopModel: {
      get(): boolean {
        return this.loop
      },
      set(value: boolean) {
        this.$emit("update:loop", value)
      }
    }
  },
  methods: {
    stop() {
      this.$emit("stop")
    },
    play() {
      this.$emit("play")
    },
    selectable(option: AudioEntry) {
      // TODO: remove key
      // @ts-ignore
      if (option[this.identity] === undefined) return false
      return true
    }
  }
})
