var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-player rtb-d-flex items-center" },
    [
      _c("rtb-select", {
        staticClass: "audio-player__select",
        attrs: {
          options: _vm.items,
          "option-text": "name",
          label: "Audio",
          size: "sm",
          "hide-label": "",
          identity: _vm.identity,
          selectable: _vm.selectable,
        },
        on: {
          "search:focus": function ($event) {
            return _vm.$emit("focus:select")
          },
          "search:blur": function ($event) {
            return _vm.$emit("blur:select")
          },
        },
        model: {
          value: _vm.valueModel,
          callback: function ($$v) {
            _vm.valueModel = $$v
          },
          expression: "valueModel",
        },
      }),
      _vm.value !== undefined && _vm.active
        ? _c(
            "rtb-button",
            {
              key: "stop",
              staticClass: "ml-1",
              attrs: {
                variant: "icon",
                size: "sm",
                color: "transparent-light",
                title: "Stop",
              },
              on: { click: _vm.stop },
            },
            [_c("svg-icon", { attrs: { name: "stop-solid" } })],
            1
          )
        : _vm._e(),
      _vm.value !== undefined && !_vm.active
        ? _c(
            "rtb-button",
            {
              key: "play",
              staticClass: "ml-1",
              attrs: {
                variant: "icon",
                size: "sm",
                color: "transparent-light",
                title: "Play",
              },
              on: { click: _vm.play },
            },
            [_c("svg-icon", { attrs: { name: "play-solid" } })],
            1
          )
        : _vm._e(),
      _c("v-divider", {
        staticStyle: { "margin-top": "6px", "margin-left": "5px" },
        attrs: { vertical: "" },
      }),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c("rtb-checkbox", {
                        staticClass: "flex-shrink-0",
                        attrs: { label: "Loop", "hide-label": "" },
                        model: {
                          value: _vm.loopModel,
                          callback: function ($$v) {
                            _vm.loopModel = $$v
                          },
                          expression: "loopModel",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Loop")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }